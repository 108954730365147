import {createRouter, createWebHashHistory} from 'vue-router'

import MainComponents from "@/components/MainComponents.vue";
import RightsDisplayComponents from "@/components/RightsDisplayComponents.vue";
import MoreQuestionComponents from "@/components/MoreQuestionComponents.vue";
import ComplaintComponents from "@/components/complaint/ComplaintComponents.vue";
import ComplaintHarmfulComponents from "@/components/complaint/ComplaintHarmfulComponents.vue";
import ComplaintScamComponents from "@/components/complaint/ComplaintScamComponents.vue";
import ComplaintGoodComponents from "@/components/complaint/ComplaintGoodComponents.vue";
import ComplaintInfringementComponents from "@/components/complaint/ComplaintInfringementComponents.vue";
import VoideTestComponents from "@/views/VoideComponents.vue";
import LiveCodeShowComponents from "@/views/LiveCodeShowComponents.vue"

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: MainComponents,
        },
        {
            path: '/liveCode/:liveCodeId/',
            name: 'liveCode',
            component: LiveCodeShowComponents,
        },
        {
            path: '/rights',
            name: 'rightsDisplay',
            component: RightsDisplayComponents,
        },
        {
            path: '/help',
            name: 'help',
            component: MoreQuestionComponents,
        },
        {
            path: '/complaint',
            name: 'complaint',
            component: ComplaintComponents,
        },{
            path: '/complaint/harmful/:complaintId',
            name: 'harmful',
            component: ComplaintHarmfulComponents,
            props: true
        },
        {
            path: '/complaint/scam/:complaintId',
            name: 'scam',
            component: ComplaintScamComponents,
            props: route => ({ title: '网络诈骗投诉', complaintId: route.params.complaintId })
        },
        {
            path: '/complaint/forgery/:complaintId',
            name: 'forgery',
            component: ComplaintScamComponents,
            props: route => ({ title: '伪造证书投诉', complaintId: route.params.complaintId })
        },
        {
            path: '/complaint/other/:complaintId',
            name: 'other',
            component: ComplaintScamComponents,
            props: route => ({ title: '其他投诉', complaintId: route.params.complaintId })
        },
        {
            path: '/complaint/good',
            name: 'good',
            component: ComplaintGoodComponents,
        },
        {
            path: '/complaint/infringement',
            name: 'infringement',
            component: ComplaintInfringementComponents,
        },
        {
            path: '/video',
            name: 'video',
            component: VoideTestComponents,
        },
    ]
})

export default router
