import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "video-body"
};
const _hoisted_2 = {
  style: {
    "height": "100%",
    "width": "100%"
  }
};
import { onMounted, ref } from "vue";
import MyVideo from "@/components/CVideo/CVideo.vue";
import { useRoute } from "vue-router";
export default {
  __name: 'VoideComponents',
  setup(__props) {
    onMounted(() => {
      if (videoTitle.value && videoTitle.value !== '') {
        document.title = videoTitle.value;
      } else {
        document.title = '视频播放';
      }
    });

    // const props = defineProps({
    //   videoUrl: {
    //    type: String,
    //    default: ''
    //   },
    //   posterUrl: {
    //     type: String,
    //     default: ''
    //     // default: 'http://1018908855859426048.video.huichangliang.com/1.jpg'
    //   },
    //   videoTitle: {
    //     type: String,
    //     default: ''
    //   }
    // })
    const videoUrl = ref('');
    const posterUrl = ref('');
    const videoTitle = ref('');
    const route = useRoute();
    console.log('props', route);
    console.log('props', history.state);
    if (history.state) {
      videoUrl.value = history.state.videoUrl;
      posterUrl.value = history.state.posterUrl;
      videoTitle.value = history.state.videoTitle;
    }
    // const {videoTitle} = toRefs(props)

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(MyVideo, {
        "video-url": videoUrl.value,
        "poster-url": posterUrl.value
      }, null, 8, ["video-url", "poster-url"])])]);
    };
  }
};